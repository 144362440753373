/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "variables";
@import "~bootstrap/scss/mixins";
@import '~bootstrap/scss/bootstrap';
@import 'bootstrap';
@import '~bootstrap-vue/src/index.scss';

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/demos";


// Plugins
@import "custom/plugins/custom-scrollbar";


// Pages
@import "custom/pages/authentication";

@import "icons";

// Resourcebase
@import "resourcebase";
