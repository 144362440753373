.card {
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.breadcrumb-item {
    +.breadcrumb-item {
        &::before {
            font-family: "Font Awesome 5 Pro";
        }
    }
}

.table {
    th {
        font-weight: 600;
    }
}

.table-centered {
    th {
        vertical-align: middle !important;
    }

    td {
        vertical-align: middle !important;
    }
}

#sidebar-menu {
    .menu-item-icon {
        font-size: 16px;
        margin-right: 8px;
    }

    .has-arrow {
        &:after {
            content: "\f078";
            font-family: 'Font Awesome 5 Pro';
            font-size: 0.6rem;
            margin-top: 3px;
        }
    }
}

.vertical-collpsed {
    .vertical-menu {
        #sidebar-menu > ul > li > a {
            padding-left: 25px;
        }
    }
}

.profile-user-wid {
    margin-top: -26px;
}

label {
    margin-bottom: 0.3rem;
}

.col-form-label {
    padding-bottom: 0;
    text-align: right;
}

.field-error {
    border: 3px solid #e3342f;
}

.loading {
    color: $primary;
}

.mr2 {
    margin-right: 2px;
}

.mr5 {
    margin-right: 5px;
}

.modal-content {
    border: none;
}

.modal-header {
    border-bottom: none;
    background-color: $primary;

    h5 {
        font-weight: $font-weight-light;
        color: $primary-text;
    }

    .close {
        color: $primary-text;
    }
}

.form-control {
    &:focus {
        box-shadow: none;
        border: 3px solid $secondary;
    }
}

.client-color {
    display: inline-block;
    padding: 4px 8px;
    margin-right: 10px;
    margin-bottom: 5px;
    min-width: 25px;
    white-space: nowrap;
}

.vc-chrome {
	position: absolute;
	top: 35px;
	right: 0;
	z-index: 9;
}

.current-color {
	display: inline-block;
	width: 19px;
	height: 19px;
	background-color: #000;
	cursor: pointer;
}

.alert-warning {
    border-color: #ebe7ca;
}

.tickbox {
    color: $primary;
    font-size: 2rem;
    margin-right: 5px;
    cursor: pointer;
}

.accordion {
    .card {
        margin-bottom: 0;
        box-shadow: none;
        border-bottom: 1px solid $light-grey !important;

        .card-header {
            background-color: rgba($secondary, 0.8);
            color: $secondary-text;
            cursor: pointer;

            &:hover {
                background-color: $secondary;
            }
        }

    }
}

a {
    color: $grey;

    &:hover {
        color: rgba($grey, 0.8);
    }
}

a.primary {
    color: $primary;

    &:hover {
        color: rgba($primary, 0.8);
    }
}

.contact-links {
    a {
        color: $gray-700;

        &:hover {
            color: rgba($gray-700, 0.8) !important;
        }
    }
}

.fa-toggle-on {
    font-size: 20px;
}

.fa-toggle-on.fa-flip-horizontal {
    color: $gray-400;
    cursor: pointer;
}

.tab-content {
    background-color: #fff;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0.5rem 1rem;
}

.vertical-tabs {
    .nav-tabs {
        float: left;
        width: 165px;
        display: block;
        border-bottom: none;
        border-right: 1px solid #dee2e6;
        height: 100%;

        .nav-item {
            margin-right: -1px;

            .nav-link {
                border-top-right-radius: 0rem;
                border-bottom-left-radius: 0.25rem;

                &:hover {
                    border-right: none;
                }
            }

            .nav-link.active {
                border-color: #dee2e6 #fff #dee2e6 #dee2e6;
            }
        }
    }

    .tab-content {
        margin-left: 164px;
        border-top: 1px solid #dee2e6;
        border-bottom-left-radius: 0rem;
        border-top-right-radius: 0.25rem;
    }
}

.btn {
    border: none;

    &:disabled {
        background-color: $gray-500 !important;

        &:hover {
            background-color: $gray-500 !important;
        }
    }
}

.text-disabled {
    color: $gray-500;
}

.form-control:disabled, .form-control[readonly] {
    background-color: $gray-100;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}
